<template>
  <div class="company">
    <AboutJetlinks></AboutJetlinks>
    <Profile></Profile>
    <Team></Team>
    <Honors></Honors>
    <Contact></Contact>
  </div>
</template>

<script>
import AboutJetlinks from '@/components/company/AboutJetlinks';
import Profile from '@/components/company/Profile';
import Team from '@/components/company/Team';
import Honors from '@/components/company/Honors';
import Contact from '../components/company/Contact.vue';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    AboutJetlinks,
    Profile,
    Team,
    Honors,
    Contact
},
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  
</style>