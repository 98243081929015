<template>
  <!-- 我们的团队 -->
  <jet-card class="our-team" title="我们的团队" :titleType="2">
    <div style="margin-top: 3.125rem">
      <p class="text">
        我们是一支年轻化、灵活多变、又专又精、实战性强的物联网平台企业团队，我们致力于让物联网企业以更小的代价专注于自身应用的开发，提高物联网项目落地能力。
      </p>
      <p class="text">
        我们追求品质、专业高效、并不断积累经验实现自我超越，每一位员工都能在公司实现自己的价值，共同成长进步。流水式人员增长带来的灵活性使我们发展更为猛烈，理论功底更为深厚，经验更为丰富，技术更为精湛！！
      </p>
      <p class="text">
        我们尊重并珍惜每一次合作机会与挑战，永不止步！
      </p>
      <div class="img-list">
        <img src="@/assets/images/company/team1.png" alt="">
        <img src="@/assets/images/company/team2.png" alt="">
        <img src="@/assets/images/company/team3.png" alt="">
      </div>
    </div>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .text{
    margin-bottom: 2rem;
    text-indent: 0em;
  }
  .img-list{
    display: flex;
    margin-top: 3rem;
    justify-content: space-between;
  }
</style>