<template>
  <jet-card title="发展和荣誉" :titleType="2">
    <div @mouseover="stopPlay" style="margin-top: 3.125rem;" ref="swiper">
      <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item, index in imgArr" :key="index">
          <img :src="item.src" class="blur">
        </swiper-slide>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div style="width: 100%;margin-top: 2rem;position: relative;">
      <img src="@/assets/images/company/develop.png" style="width: 100%">
      <!-- <img src="@/assets/images/company/Frame 3915.png" style="position: absolute;left: 10%;top: 188px;">
      <img src="@/assets/images/company/Frame 3916.png" style="position: absolute;left: 10%;top: 291px;"> -->
    </div>
  </jet-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper' //引入swiper组件
export default {
  name: '',
  data() {
    return {
      swiperOption: { //swiper组件配置
        slidesPerView: 5, //一次显示多少个
        spaceBetween: 20, //每个slide间的间隔
        speed: 2500,
        autoplay: {
          delay: 0,
        }, //可选选项，自动滑动
        loop: true,
        navigation: { // 左右按钮分页器
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      imgArr: []
    }
  },
  created() {
    for(let i = 1; i <= 14; i++) {
      this.imgArr.push({
        src: require(`@/assets/images/company/honor${i}.svg`)
      })
    }
  },
  mounted() {
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    stopPlay() {
      this.$nextTick(() => {
        console.log(this.$refs.mySwiper)
      })
      // this.$refs.mySwiper.autoplay.stop();
    }
  },
}
</script>
<style scoped lang="less">
  :deep(.swiper-container .swiper-wrapper){
    -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
  .blur{
    filter: blur(0.5px);
  }
</style>