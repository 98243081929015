<template>
  <!-- 关于jetlinks -->
  <div class="about-jetlinks">
    <h3>关于JetLinks，了解旱獭</h3>
    <h1>全国知名开源物联网基础平台软件供应商</h1>
    <p>——物联改变世界，创新引领未来——</p>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .about-jetlinks{
    width: 100%;
    height: 44.8125rem;
    background: url('~assets/images/company/about-bg.png') no-repeat center center/100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    h3{
      background: linear-gradient(268.24deg, #BA60F1 12%, #0E6EFF 80.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 2.5rem;
    }
    h1{
      margin-top: 1.875rem;
      font-size: 4rem;
      color: #0E6EFF;
    }
    p{
      color: #0B1145;
      font-size: 1.5rem;
    }
  }
</style>