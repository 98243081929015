<template>
  <jet-card class="profile" title="公司概况" :titleType="2">
    <a-row :gutter="40" style="margin-top: 2rem">
      <a-col :span="14">
        <p>
          重庆旱獭信息技术有限公司是行业应用系统+物联网方案提供商，依托于自主研发的JetLinks软件，从了解中双赢，从合作中共进，积极大力投入于物联网相关领域/行业发展，致力于帮助各行业企业以低研发、低运营、低维护成本快速创建各种业务场景，实现搭建高效、稳定、安全的物联网平台。
        </p>
        <p>
          公司立足重庆精耕细作，始终以客户需求为导向，在不断自我提升的基础上提供场景化定制服务，从产品+研发+服务出发，多方位、多效能满足各类需求，与各领域诸多企业达成商业合作，长久以来，在行业内树立了良好口碑与宣传效应。
        </p>
      </a-col>
      <a-col :span="10">
        <img src="@/assets/images/company/work.png" alt="">
      </a-col>
    </a-row>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .profile{
    p{
      line-height: 2rem;
      font-size: 1.125rem;
      color: rgba(11, 17, 69, 0.8);
      margin-bottom: 3rem;
      word-wrap: normal; 
      word-break: normal;
    }
    img{
      width: 30.75rem;
      margin-top: 1rem;
    }
  }
</style>